import React from "react";
import { Button, Link } from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";

import Paragraph from "../ui/common/Paragraph";
import Section from "../ui/common/Section";

const items = [
  {
    title: "June 2022",
    text: (
      <>
        <Button
          rightIcon={<DownloadIcon />}
          colorScheme="teal"
          variant="outline"
        >
          <Link
            href="/static/newsletter/microPublication Summer News 2022.pdf"
            isExternal
          >
            Download
          </Link>
        </Button>

        <Paragraph>
          Read about new developments and collaborations at microPublication.
        </Paragraph>
      </>
    ),
  },
  {
    title: "December 2021",
    text: (
      <>
        <Button
          rightIcon={<DownloadIcon />}
          colorScheme="teal"
          variant="outline"
        >
          <Link href="/static/newsletter/5_Newsletter.pdf" isExternal>
            Download
          </Link>
        </Button>

        <Paragraph>
          Read about upcoming developments and collaborations at
          microPublication.
        </Paragraph>
      </>
    ),
  },
  {
    title: "November 2020",
    text: (
      <>
        <Button
          rightIcon={<DownloadIcon />}
          colorScheme="teal"
          variant="outline"
        >
          <Link
            href="/static/newsletter/microPublication_Fall_News_2020.pdf"
            isExternal
          >
            Download
          </Link>
        </Button>

        <Paragraph>
          This newsletter celebrates our inclusion in PubMed, the addition of S.
          pombe to our community, a partnership with Bio-protocol and some new
          features and updates.
        </Paragraph>
      </>
    ),
  },
  {
    title: "May 2020",
    text: (
      <>
        <Button
          rightIcon={<DownloadIcon />}
          colorScheme="teal"
          variant="outline"
        >
          <Link href="/static/newsletter/3-Newsletter.pdf" isExternal>
            Download
          </Link>
        </Button>
        <Paragraph>
          This newsletter includes our new partnerships, indexing updates, Open
          Publishing Fest, our new submission portal and an introduction to our
          new developer.
        </Paragraph>
      </>
    ),
  },
  {
    title: "December 2018",
    text: (
      <>
        <Button
          rightIcon={<DownloadIcon />}
          colorScheme="teal"
          variant="outline"
        >
          <Link
            href="/static/newsletter/microPublication-Newsletter-2.pdf"
            isExternal
          >
            Download
          </Link>
        </Button>
        <Paragraph>
          This newsletter introduces our editorial board, our support for CURE
          teaching, using CRediT for author contribution and summary of the
          meetings we have been to and plan to go to.
        </Paragraph>
      </>
    ),
  },
  {
    title: "May 2018",
    text: (
      <>
        <Button
          rightIcon={<DownloadIcon />}
          colorScheme="teal"
          variant="outline"
        >
          <Link
            href="/static/newsletter/2018.05.11-newsletter-001.pdf"
            isExternal
          >
            Download
          </Link>
        </Button>
        <Paragraph>
          Our first newsletter including our mission, project update, Bay Area
          Worm Meeting contest winners and an update on our platform
          development.
        </Paragraph>
      </>
    ),
  },
  {
    title: "",
    text: (
      <Paragraph>
        Subscribe (or unsubscribe) to our low traffic{" "}
        <Link
          href="https://groups.google.com/a/micropublication.org/forum/#!forum/news"
          isExternal
        >
          ​announcement mailing list​
        </Link>
        .
      </Paragraph>
    ),
  },
];

const NewsletterSection = (props) => {
  const { newsletterRef, isPage } = props;

  return (
    <Section
      items={items}
      title="Newsletter"
      sectionRef={newsletterRef}
      isPage={isPage}
    />
  );
};

export default NewsletterSection;
