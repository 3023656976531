import React from "react";

import Layout from "../components/Layout";
import NewsletterSection from "../content/NewsletterSection";

const Newsletter = (props) => {
  return (
    <Layout>
      <NewsletterSection isPage />
    </Layout>
  );
};

export default Newsletter;
